import React, { useRef, useState, useEffect } from "react";
import { all_components } from "../Components";
import { parseConfig } from "../Components/processes/parseConfig";

import { isArray, cloneDeep, create_UUID, deepEqual } from "../Components/tasks/utils";

import styled from 'styled-components';

const event_registry = {}
const component_cache = {}

function extractStringBeforeUUID(input) {
  // Regular expression to match any string ending with a UUID
  // Captures everything before the UUID
  const regex = /^(.*?)-[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;
  const match = input.match(regex);

  // If a match is found, return the first captured group (the string before the UUID)
  // Otherwise, return an empty string
  return match ? match[1] : "";
}

function simpleHash(str) {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
      const char = str.charCodeAt(i);
      hash = (hash << 5) - hash + char;
      hash |= 0; // Convert to 32bit integer
  }
  return hash.toString();
}

// class ErrorBoundary extends React.Component {
//   constructor(props) {
//     super(props);
//     this.state = { hasError: false };
//   }

//   static getDerivedStateFromError(error) {
//     // Update state so the next render will show the fallback UI.
//     return { hasError: true };
//   }

//   componentDidCatch(error, errorInfo) {
//     // You can also log the error to an error reporting service
//     console.log(error, errorInfo);
//   }

//   render() {
//     if (this.state.hasError) {
//       // You can render any custom fallback UI
//       return <h1>Something went wrong.</h1>;
//     }

//     return this.props.children;
//   }
// }

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can log the error to an error reporting service if needed
    this.setState({
      error: {
        message: error.message,
        stack: error.stack, // You can choose what to show from the error and errorInfo objects
        componentStack: errorInfo.componentStack
      }
    });
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI using the state error information
      return (
        <div style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          color: 'darkgray',
          padding: '1rem',
          width: '100%',
          height: '100%'
          }}>
          <h1 style={{
          width: 'fit-content',
          textAlign: 'center'
          }}>something went wrong...</h1>
          <p style={{ 
            whiteSpace: 'pre-wrap',
            width: 'fit-content',
            textAlign: 'center' 
            }}>
            {this.state.error && this.state.error.message}
          </p>
        </div>
      );
    }

    return this.props.children;
  }
}


export function UnstyledRenderer(props) {
  const {
    app_id,
    component_id,
    parent_eventctl,
    cssString,
    className,
    _is_child
  } = props;

  // console.log('cssString: ', cssString, className)
 //console.log("component_config: ", cloneDeep(component_config));
 //console.log("component_state: ", cloneDeep(component_state));
 //console.log("component_inputs: ", cloneDeep(component_inputs));
 //console.log("component_events: ", cloneDeep(component_events));

  if(component_cache[app_id] == null){
    component_cache[app_id] = {}
  }

  const state_original = useRef({});
  const inputs_original = useRef({});
  const events = useRef({});
  const children_events = useRef({});

  const onload_funcs = useRef({});
  const onrender_funcs = useRef({});

  const vars = useRef({
    initial: true,
    component_id: null,
    component_config: null,
    component_state: null,
    component_inputs: null,
    component_event: null,
    id: create_UUID()
  });

  let component_inputs
  // console.log('component_id comparison:', vars.current.component_id!=component_id, vars.current.component_id, component_id)
  if(vars.current.component_inputs==null || vars.current.component_id!=component_id){
    component_inputs = props.component_inputs
  }else{
    component_inputs = vars.current.component_inputs
  }

  const [renderCounter, setRenderCounter] = useState(null);
  const render_vars = useRef({
    renderCounter: renderCounter,
  });
  const increaseRenderCounter = () => {
    render_vars.current.renderCounter = render_vars.current.renderCounter + 1;
    setRenderCounter(render_vars.current.renderCounter);
  };

  const eventctl = {
    emit: (event, event_inputs, target) => {
      // console.log(
      //   "events ch",
      //   events.current,
      //   event,
      //   event_inputs,
      //   eventctl ? eventctl.get_id() : eventctl,
      //   emitter.get_id(),
      //   reemitter ? reemitter.get_id() : reemitter,
      //   cloneDeep(state.current)
      // );
      // console.log(event, target, typeof target);
      const results = {}
      Object.entries(event_registry).forEach(([element_id, element_events]) => {
        if( typeof target === "undefined"){
          const element_name = extractStringBeforeUUID(element_id)
          if (element_events[event]){
            results[element_name] = element_events[event](...event_inputs);
          }
        }else{
          const element_name = extractStringBeforeUUID(element_id)
          if(element_name==target && element_name.length > 0){
            if (element_events[event]){
              results[element_name] = element_events[event](...event_inputs);
            }
          }
        }
      })
      return results
      // if (events.current[event]) {
      //   if (event_inputs && isArray(event_inputs)) {
      //     const results = events.current[event](...event_inputs);
      //     const parent_results = eventctl.passToParent(
      //       event,
      //       event_inputs,
      //       emitter,
      //       reemitter
      //     );
      //     const children_results = eventctl.passToChildren(
      //       event,
      //       event_inputs,
      //       emitter,
      //       reemitter
      //     );
      //     return [results, parent_results, children_results];
      //   } else {
      //     const results = events.current[event]();
      //     const parent_results = eventctl.passToParent(
      //       event,
      //       event_inputs,
      //       emitter,
      //       reemitter
      //     );
      //     const children_results = eventctl.passToChildren(
      //       event,
      //       event_inputs,
      //       emitter,
      //       reemitter
      //     );
      //     return [results, parent_results, children_results];
      //   }
      // } else {
      //   const parent_results = eventctl.passToParent(
      //     event,
      //     event_inputs,
      //     emitter,
      //     reemitter
      //   );
      //   const children_results = eventctl.passToChildren(
      //     event,
      //     event_inputs,
      //     emitter,
      //     reemitter
      //   );
      //   return [null, parent_results, children_results];
      // }
    },
    add_onload: (func_id, onload_func, func_inputs) => {      
      onload_funcs.current[func_id] = [onload_func, func_inputs]
    },
    remove_onload: (func_id) => {      
      delete onload_funcs.current[func_id]
    },
    add_onrender: (func_id, onrender_func, func_inputs) => {      
      onrender_funcs.current[func_id] = [onrender_func, func_inputs]
    },
    remove_onrender: (func_id) => {      
      delete onrender_funcs.current[func_id]
    },
    passToParent: (event, event_inputs, emitter, reemitter) => {
      //console.log("passToParent: ", parent_eventctl)
      if (
        parent_eventctl &&
        (typeof reemitter === "undefined" ||
          reemitter.get_id() !== parent_eventctl.get_id())
      ) {
        // console.log("emitting parent", parent_eventctl.get_id(), eventctl.get_id(), emitter.get_id(), reemitter ? reemitter.get_id() : reemitter)
        return parent_eventctl.emit(event, event_inputs, emitter, eventctl);
      }
    },
    passToChildren: (event, event_inputs, emitter, reemitter) => {
      // console.log("CHILDREN!!!!!: ",
      // cloneDeep(state.current),
      // cloneDeep(inputs.current),
      // cloneDeep(children_events.current),
      // eventctl.get_id(), emitter.get_id(), reemitter ? reemitter.get_id() : reemitter);
      const children_id = Object.keys(children_events.current);
      const children_results = [];
      children_id.forEach((child_id) => {
        if (
          emitter.get_id() !== child_id &&
          (typeof reemitter === "undefined" || reemitter.get_id() !== child_id)
        ) {
          children_results.push(
            children_events.current[child_id].emit(
              event,
              event_inputs,
              emitter,
              eventctl
            )
          );
          // console.log("emitting child", eventctl, emitter, reemitter)
        }
      });
      return children_results;
    },
    addChild: (child_eventctl) => {
      // console.log("children_events: ", cloneDeep(children_events), child_eventctl)
      children_events.current[child_eventctl.get_id()] = child_eventctl;
    },
    removeChild: (child_eventctl) => {
      // console.log("remove children_events: ", cloneDeep(children_events), child_eventctl)
      delete children_events.current[child_eventctl.get_id()]
    },
    loadedChild: (child_id) =>{
      vars.current.rendered_component_children.push(child_id)
    },
    get_id: () => {
      return vars.current.id;
    },
    runOnloadFuncs: () => {
      const all_rendered = Object.keys(children_events.current).length == vars.current.rendered_component_children.length
      if (all_rendered){
        Object.values(onload_funcs.current).forEach((onload_config) => {
          onload_config[0](...onload_config[1])
        })
        vars.current.initial_full_render = false
        if(parent_eventctl!=null){
          parent_eventctl.loadedChild(eventctl.get_id())
          parent_eventctl.runOnloadFuncs()
        }
      }
    },
  };

  if (parent_eventctl) {
    parent_eventctl.addChild(eventctl);
  }

  if ((vars.current.component_config == null && vars.current.component_state == null && vars.current.component_events == null ) || ((vars.current.component_id == null || vars.current.component_id != component_id) && component_id != null)) {
    // console.log("no component data:", component_id, vars.current.component_config, vars.current.component_state, vars.current.component_events, component_cache)
    useEffect(() => {
      return () => {
        if (parent_eventctl) {
          parent_eventctl.removeChild(eventctl);
        }
        delete event_registry[vars.current.id]
      };
    }, []);
  
    useEffect(() => {
      Object.values(onload_funcs.current).forEach((onload_config) => {
        onload_config[0](...onload_config[1])
      })
  
      return () => {};
    }, []); 
  
    useEffect(() => {
      Object.values(onrender_funcs.current).forEach((onrender_config) => {
        onrender_config[0](...onrender_config[1])
      })
  
      return () => {};
    });
    // console.log(component_cache, component_id, component_cache[app_id][component_id])
    if(component_cache[app_id][component_id] !== null && component_cache[app_id][component_id] !== undefined){
      if(component_cache[app_id][component_id] instanceof Promise){
        component_cache[app_id][component_id].then((data)=>{
          // console.log("fetch data:", data)
          vars.current.component_config = data["component_config"]
          vars.current.component_state = data["component_state"]
          vars.current.component_events = data["component_events"]
          vars.current.initial = true
          vars.current.initial_full_render= true
          vars.current.rendered_component_children = []
          let merging_inputs = cloneDeep(data["component_inputs"])
  
          if(component_inputs){
            Object.entries(component_inputs).forEach(([input_name, input_value]) => {
              // console.log("merged input:", Object.keys(merging_inputs), input_name, input_value)
              if(Object.keys(merging_inputs).includes(input_name)){
                merging_inputs[input_name] = input_value 
              }
            })
            // console.log("merged inputs:", merging_inputs, component_inputs)
          }
  
          vars.current.component_inputs = merging_inputs
  
          vars.current.component_id = component_id
  
          component_cache[app_id][component_id] = data
  
          increaseRenderCounter()
        })
        return <div data-twlcStatus='loading'></div>
      }else{
        const component_data = component_cache[app_id][component_id]
        vars.current.component_config = component_data["component_config"]
        vars.current.component_state = component_data["component_state"]
        vars.current.component_events = component_data["component_events"]
        vars.current.initial = true
        vars.current.initial_full_render = true
        vars.current.rendered_component_children = []
        let merging_inputs = cloneDeep(component_data["component_inputs"])
  
        // console.log("component_data: ", component_data, component_id, merging_inputs===component_cache[app_id][component_id]["component_inputs"])
  
        if(component_inputs){
          Object.entries(component_inputs).forEach(([input_name, input_value]) => {
            if(Object.keys(merging_inputs).includes(input_name)){
              merging_inputs[input_name] = input_value 
            }
          })
          // console.log("merged inputs:", merging_inputs, component_inputs)
        }
  
        vars.current.component_inputs = merging_inputs
  
        vars.current.component_id = component_id
        increaseRenderCounter()
        return <div data-twlcStatus='loading'></div>
      }
    }else{
      //fetch the component data from server and save to component_cache
      component_cache[app_id][component_id] = fetch(`/${app_id}/${component_id}?response_type=data`).then(response => response.json())
      component_cache[app_id][component_id].then((data)=>{
        // console.log("fetch data:", data)
        vars.current.component_config = data["component_config"]
        vars.current.component_state = data["component_state"]
        vars.current.component_events = data["component_events"]
        vars.current.initial = true
        vars.current.initial_full_render= true
        vars.current.rendered_component_children = []
        let merging_inputs = cloneDeep(data["component_inputs"])

        if(component_inputs){
          Object.entries(component_inputs).forEach(([input_name, input_value]) => {
            // console.log("merged input:", Object.keys(merging_inputs), input_name, input_value)
            if(Object.keys(merging_inputs).includes(input_name)){
              merging_inputs[input_name] = input_value 
            }
          })
          // console.log("merged inputs:", merging_inputs, component_inputs)
        }

        vars.current.component_inputs = merging_inputs

        vars.current.component_id = component_id

        component_cache[app_id][component_id] = data

        increaseRenderCounter()
      })
      return <div data-twlcStatus='loading'></div>
    }
  }else{
    // console.log("component data loaded:", component_id, vars.current.component_config, vars.current.component_state, vars.current.component_events, component_cache)
    const component_config = vars.current.component_config
    const component_state = vars.current.component_state
    const component_inputs = vars.current.component_inputs
    const component_events = vars.current.component_events
    
    const proxyRegistry = new WeakSet();

    if (vars.current.initial) {
      state_original.current = { ...cloneDeep(component_state) };
      // console.log("renderer_states: ", cloneDeep(state_original.current), props.key, cloneDeep(component_config))
      events.current = { ...component_events };
      // vars.current.id = create_UUID();
    }
  
    inputs_original.current = { ...cloneDeep(component_inputs) };
    // console.log("renderer_inputs: ", cloneDeep(inputs_original))

    const array_state_handler = {
      get: (target, key) => {
        //console.log("get array_state_handler: ", isArray(target[key]), target, key, target[key])
        if (
          typeof target[key] === "object" &&
          target[key] !== null &&
          !isArray(target[key]) && !proxyRegistry.has(target[key])
        ) {
          const newProxy = new Proxy(target[key], state_handler);
          proxyRegistry.add(newProxy)
          return newProxy
        }
        if (isArray(target[key]) && !proxyRegistry.has(target[key])) {
          const newProxy = new Proxy(target[key], array_state_handler);
          proxyRegistry.add(newProxy)
          return newProxy
        }
        // const result = target[key]
        // console.log('get-result: ', result)
        // return typeof result === 'undefined'? null : result;
        return target[key]
      },
      set: (target, prop, value) => {
        if (!deepEqual(target[prop], value)) {
         //console.log("set array_state_handler: ", target, prop, value)
          target[prop] = value;
          increaseRenderCounter();
          return true;
        }
        return true;
      },
    };
  
    const state_handler = {
      get: (target, key) => {
        //console.log("get state_handler: ", isArray(target[key]), target, key, target[key])
        if (
          typeof target[key] === "object" &&
          target[key] !== null &&
          !isArray(target[key]) && !proxyRegistry.has(target[key])
        ) {
          //console.log("get state_handler: as object proxy")
          const newProxy = new Proxy(target[key], state_handler);
          proxyRegistry.add(newProxy)
          return newProxy
        }
        if (isArray(target[key]) && target.watchList && target.watchList.includes(key) && !proxyRegistry.has(target[key])) {
          //console.log("get state_handler: as array proxy")
          const newProxy = new Proxy(target[key], array_state_handler);
          proxyRegistry.add(newProxy)
          return newProxy
        }
        //console.log("get state_handler: no proxy")
        // const result = target[key]
        // console.log('get-result: ', result)
        // return typeof result === 'undefined'? null : result;
        return target[key]
  
      },
      set: (target, prop, value) => {
        // console.log("set state_handler: ", target, prop, value)
        if (target.watchList) {
          // console.log("set state_handler pre-watchlist: ", target, prop, value)
          if (
            target.watchList.includes(prop) &&
            !deepEqual(target[prop], value)
          ) {
            target[prop] = value;
            increaseRenderCounter();
          } else {
            target[prop] = value;
          }
        } else {
          target[prop] = value;
        }
  
        return true;
      },
    };
  
    const state = new Proxy(state_original, state_handler);
  
    const inputs_handler = {
      get: (target, key) => {
        if (typeof target[key] === "object" && target[key] !== null && !proxyRegistry.has(target[key])) {
          const newProxy = new Proxy(target[key], inputs_handler);
          proxyRegistry.add(newProxy)
          return newProxy
        }
        return target[key];
      },
      set: (target, prop, value) => {
       //console.log("cannot set input");
        return true;
      },
    };
  
    const inputs = new Proxy(inputs_original, inputs_handler);
  
    if (vars.current.initial) {
      if (parent_eventctl) {
        parent_eventctl.addChild(eventctl);
      }
  
      Object.keys(events.current).forEach((event_str) => {
        events.current[event_str] = parseConfig(
          events.current[event_str],
          state.current,
          inputs.current,
          eventctl
        );
      });
  
      if (typeof component_config.name === "undefined"){
        event_registry[vars.current.id] = events.current
      }else{
        event_registry[component_config.name+"-"+vars.current.id] = events.current
      }
    }
    
    const render_props = (object) => {
      function processProps(obj) {
        const allowedKeys = ['type', 'props', 'children', 'name', 'key', 'render']
        if (obj != null) {
          Object.keys(obj).forEach(prop_key => {
              // Check if the property is an object and not null
              if (typeof obj[prop_key] === 'object' && obj[prop_key] != null) {
                  // If the object has a 'type' key, process it with render_elements
                  if (
                    'type' in obj[prop_key] && 
                    obj[prop_key].type in all_components &&
                    Object.keys(obj[prop_key]).filter(key => {return  !allowedKeys.includes(key)}).length == 0 &&
                    obj[prop_key].render
                  ) {
                      // console.log("rendering-prop: ", obj[prop_key])
                      obj[prop_key] = render_elements(obj[prop_key], obj[prop_key].name);
                      // console.log("rendered-prop: ", obj[prop_key])
                  }
                  // Recursively process nested objects
                  processProps(obj[prop_key]);
              }
          });
        }
      }
  
      processProps(object); // Start the recursive process
      return object;
    }

    const render_elements = (element, name) => {
      // console.log("render_elements:", element, name)
      if (element && element.children) {
        const children = [];
        element.children.forEach((child) => {
          if (child) {
            const name = child.name;
            const child_id = create_UUID();
            if (child && child.type === "component") {
              // state.current[child_id] = child.state; 
              // console.log("child", child, cloneDeep(state))
              children.push(
                <Renderer
                  key={child.hasOwnProperty('key') ? String(child.key) : child.name + simpleHash(JSON.stringify(child.inputs ? child.inputs : {}))}
                  data-twlcName={child.name}
                  app_id={app_id}
                  component_id={child.name}
                  component_inputs={child.inputs}
                  parent_eventctl={eventctl}
                  cssString={child.cssString}
                  className={className}
                  _is_child={true}
                />
              );
            } else {
             // console.log("child", child, child.name);
              if (child.type) {
                children.push(render_elements(child, child.name));
              }
            }
          }
        });
        let elem_name = typeof name == 'string' ? name : ""
        render_props(element.props)
        let rendered_element = null
        if(all_components[element.type]){
          rendered_element = (
            <ErrorBoundary>
              {React.createElement(
                all_components[element.type],
                { ...element.props, key: element.hasOwnProperty('key') ? String(element.key) : elem_name, 'data-twlcName': elem_name, 'className': element.props.className +" "+ className+" "+ elem_name},
                children
              )}
            </ErrorBoundary>
          )
        }
        return rendered_element;
      } else {
        if (element) {
          if (element && element.type === "component") {
            return (
              <Renderer
                key={element.hasOwnProperty('key') ? String(element.key) : element.name + simpleHash(JSON.stringify(element.inputs ? element.inputs : {}))}
                data-twlcName={element.name}
                app_id={app_id}
                component_id={element.name}
                component_inputs={element.inputs}
                parent_eventctl={eventctl}
                cssString={element.cssString}
                className={className}
                _is_child={false}
              />
            )
          }
          if (
            element.type &&
            Object.keys(all_components).includes(element.type)
          ) {
            let elem_name = typeof name == 'string' ? name : ""
            render_props(element.props)
            let rendered_element = null
            if(all_components[element.type]){
              rendered_element = (
                <ErrorBoundary>
                  {React.createElement(
                    all_components[element.type],
                    { ...element.props, key: element.hasOwnProperty('key') ? String(element.key) : elem_name, 'data-twlcName': elem_name, 'className': element.props.className +" "+ className+" "+ elem_name }
                  )}
                </ErrorBoundary>
              )
            }
            return rendered_element;
          }
        }
      }
    };
  
  
    let parsed_config
    try {
      parsed_config = parseConfig(
      cloneDeep(component_config),
      state.current,
      inputs.current,
      eventctl
    );
    } catch ({ name, message }) {
      console.dir(error);
      parsed_config = {
        name:"error",
        type:"text",
        props:{
          text:`${name}-${message}`,
          style:{
            position: 'fixed',
            bottom: '1rem',
            left: '1rem'
          }
        }
      }
    }
    
    
    // console.log(
    //     "parsed_config",
    //     parsed_config,
    //     state.current,
    //     inputs.current,
    //     eventctl
    //   );
  
    useEffect(() => {
      return () => {
        if (parent_eventctl) {
          parent_eventctl.removeChild(eventctl);
        }
        delete event_registry[vars.current.id]
      };
    }, []); 
  
    let rendered_elements
    try {
      rendered_elements = render_elements(parsed_config, parsed_config!=null && parsed_config.hasOwnProperty('name') ? parsed_config.name : props["data-twlcName"]);
    } catch (error) {
      console.dir(error);
      rendered_elements = <p style="{'fontSize': '.5rem'}" >render failed</p>;
    }
  
    useEffect(() => {
      Object.values(onload_funcs.current).forEach((onload_config) => {
        onload_config[0](...onload_config[1])
      })
  
      return () => {};
    }, []); 
  
    useEffect(() => {
      if (vars.current.initial_full_render) {
        eventctl.runOnloadFuncs()
      }

      Object.values(onrender_funcs.current).forEach((onrender_config) => {
        onrender_config[0](...onrender_config[1])
      })
  
      return () => {};
    });

    if (vars.current.initial) {
      vars.current.initial = false;
    }
  
    return rendered_elements
  }
}

const Renderer = styled(UnstyledRenderer)`
${props => props.cssString}
`
export default Renderer;