import * as utils from "../tasks/utils"
import * as dataProcessing from "../tasks/dataProcessing"
import * as stateManagement from "../tasks/stateManagement"
import * as constantsManagement from "../tasks/constantsManagement"
import * as storageManagement from "../tasks/storageManagement"
import * as dateManagement from "../tasks/dateManagement"
import * as cookieManagement from "../tasks/cookieManagement"
import * as eventManagement from "../tasks/eventManagement"
import * as inputsManagement from "../tasks/inputsManagement"
import * as httpApi from "../tasks/httpApi"
import * as math from "../tasks/math"
import * as responsive from "../tasks/responsive"
import * as logic from "../tasks/logic"
import * as layout from "../tasks/layout"
import * as metaManagement from "../tasks/metaManagement"
import * as documentManagement from "../tasks/documentManagement"
import * as fileManagement from "../tasks/fileManagement"


String.prototype.format = function () {
  var i = 0, args = arguments;
  return this.replace(/{}/g, function () {
    return typeof args[i] != 'undefined' ? args[i++] : '';
  });
};

const processes = {
  'dim': utils.dim,
  'duplicate': utils.duplicate,
  'extract': utils.extract,
  'remove_nested': utils.remove_nested,
  'pack': utils.pack,
  'interpolate': utils.interpolate,
  'pack_into': utils.pack_into,
  'add_item': utils.add_item,
  'remove_item': utils.remove_item,
  'join_string': utils.join_string,
  'isPlainObject': utils.isPlainObject,
  'isArray': utils.isArray,
  'parameters': utils.parameters,
  'isNumber': utils.isNumber,
  'isBoolean': utils.isBoolean,
  'isString': utils.isString,
  'isText': utils.isString,
  'isArray': utils.isArray,
  'isFunction': utils.isFunction,
  'map': utils.map,
  'log': utils.log,
  'getLog': utils.getLog,
  'block': utils.block,
  'text': utils.text,
  'keys': utils.keys,
  'entries': utils.entries,
  'fromEntries': utils.from_entries,
  'values': utils.values,
  'length': utils.length_of,
  'ifThis': utils.ifThis,
  'merge': utils.merge,
  'mergeList': utils.mergeList,
  'renderer': utils.renderer,
  'overwrite': utils.overwrite,
  'listToObject': utils.listToObject,
  'cloneObject': utils.cloneObject,
  'objectToList': utils.objectToList,
  'includes': utils.includes_item,
  'loose_match': utils.loose_match,
  'transformObj': utils.transformObj,
  'formatStr': utils.formatStr,
  'wrapArray': utils.wrapArray,
  'goTo': utils.goTo,
  'goBack': utils.goBack,
  'merge_objects': utils.merge_objects,
  'sliceObj': utils.sliceObj,
  'cloneDeep': utils.cloneDeep,
  'deepEqual': utils.deepEqual,
  'lowercase': utils.lowercase,
  'uppercase': utils.uppercase,
  'capitalize': utils.capitalize,
  'filter_objects': utils.filter_objects,
  'split_string': utils.split_string,
  'sort_objects': utils.sort_objects,
  'split_string': utils.split_string,
  'pass': utils.pass_,
  'type': utils.type_,
  'speakText': utils.speakText,
  'clipboardCopy': utils.clipboardCopy,
  'searchNested': utils.searchNested,
  'animate': utils.animate,
  'set_timeout': utils.set_timeout,
  'clear_timeout': utils.clear_timeout,
  'set_interval': utils.set_interval,
  'clear_interval': utils.clear_interval,
  'is_null': utils.is_null,
  'to_number': utils.to_number,
  'to_phonenumber': utils.to_phonenumber,
  'query': dataProcessing.query,
  'apply': dataProcessing.apply,
  'concatData': dataProcessing.concatData,
  'applyMap': dataProcessing.applyMap,
  'min': dataProcessing.min,
  'max': dataProcessing.max,
  'mean': dataProcessing.mean,
  'median': dataProcessing.median,
  'mode': dataProcessing.mode,
  'round': dataProcessing.round,
  'sum': dataProcessing.sum,
  'std': dataProcessing.std,
  'variance': dataProcessing.variance,
  'describe': dataProcessing.describe,
  'iloc': dataProcessing.iloc,
  'emit': eventManagement.emit,
  'add_onload': eventManagement.add_onload,
  'add_onrender': eventManagement.add_onrender,
  'remove_onload': eventManagement.remove_onload,
  'remove_onrender': eventManagement.remove_onrender,
  'throttle': eventManagement.throttle,
  'setState': stateManagement.setState,
  'getState': stateManagement.getState,
  'getConstant': constantsManagement.getConstant,
  'setCookie': cookieManagement.setCookie,
  'getCookie': cookieManagement.getCookie,
  'getInput': inputsManagement.getInput,
  'apiGet': httpApi.get,
  'apiPost': httpApi.post,
  'apiFileUpload': httpApi.file_upload,
  'apiGoto': httpApi.goto,
  'math': math.math,
  'add': math.add,
  'subtract': math.subtract,
  'multiply': math.multiply,
  'divide': math.divide,
  'compare': math.compare,
  'round_number': math.round_number,
  'floor_number': math.floor_number,
  'ceiling_number': math.ceiling_number,
  'eval_equation': math.eval_equation,
  'formatNum': math.formatNum,
  'derive_equation': math.derive_equation,
  'responsiveSwitch': responsive.responsiveSwitch,
  'duplicateElement': layout.duplicateElement,
  'do_if': logic.do_if,
  'do_for': logic.do_for,
  'do_foreach': logic.do_foreach,
  'while_do': logic.while_do,
  'and': logic.and,
  'or': logic.or,
  'not': logic.not,
  'equal': logic.equal,
  'run': logic.run,
  'chain': logic.chain,
  'do_switch': logic.do_switch,
  'do_recursive': logic.do_recursive,
  'now': dateManagement.now,
  'getDate': dateManagement.getDate,
  'getWeekNumber': dateManagement.getWeekNumber,
  'getCalendarDays': dateManagement.getCalendarDays,
  'dateBefore': dateManagement.dateBefore,
  'dateAfter': dateManagement.dateAfter,
  'dateSame': dateManagement.dateSame,
  'dateSameBefore': dateManagement.dateSameBefore,
  'dateSameAfter': dateManagement.dateSameAfter,
  'dateBetween': dateManagement.dateBetween,
  'dateDiff': dateManagement.dateDiff,
  'getStorage': storageManagement.getStorage,
  'setStorage': storageManagement.setStorage,
  'removeStorage': storageManagement.removeStorage,
  'clearStorage': storageManagement.clearStorage,
  'setThemeColor': metaManagement.setThemeColor,
  'getByClassName': documentManagement.getByClassName,
  'getById': documentManagement.getById,
  'setByClassName': documentManagement.setByClassName,
  'setById': documentManagement.setById,
  'focusByClassName': documentManagement.focusByClassName,
  'scrollIntoView': documentManagement.scrollIntoView,
  'readExcelFile': fileManagement.readExcelFile
}

export const parseConfig = (config, state, inputs, eventctl) => {
  const parsed_config = {};
  if ( utils.isPlainObject(config) ){
    if (config.type && config.type ==="component") {
      // config.config = parseConfig(config.config)
      if ( utils.isPlainObject(config.name) ) {
        config.name = parseConfig(config.name, state, inputs, eventctl)
      }
      if (config.parse_inputs && config.inputs) {
        config.inputs = parseConfig(config.inputs, state, inputs, eventctl)
       //console.log("PARSECONFIG CONFIG: ", config)
      }
      return config
    }

    if(config.process){
      let result = evalProcess(config, state, inputs, eventctl);
      if(config.skip_parse_output){
      }else{
        result = parseConfig(result, state, inputs, eventctl);
      }
      
     //console.log("process CH: ", config, result ? utils.cloneObject(result) : result)
      return result
    }

    Object.entries(config).forEach(([name, value]) => {
      if ( utils.isPlainObject(value) ){
        if(value.process){
         //console.log("CHCH value.process", name, value);
          parsed_config[name] = evalProcess(value, state, inputs, eventctl);
          if ( utils.isPlainObject(parsed_config[name]) ){
            if(value.skip_parse_output){
            }else{
              parsed_config[name] = parseConfig(parsed_config[name], state, inputs, eventctl);
            }
            return parsed_config[name]
          }
        }else{
          if(value.skip_parse){
            parsed_config[name] = value;
          }else{
            parsed_config[name] = parseConfig(value, state, inputs, eventctl);
          }
          return parsed_config[name]
        }
      } else {
        if (utils.isArray(value)) {
          value.forEach((element, i_element) => {
            value[i_element] = parseConfig(element, state, inputs, eventctl)
          })
          parsed_config[name] = value;
        } else {
          parsed_config[name] = value;
        }
      }
    })
  } else {
    if (utils.isArray(config)) {
      config.forEach((element, i_element) => {
        config[i_element] = parseConfig(element, state, inputs, eventctl)
       //console.log("element CH: ", config)
      })
      return config
    } else {
      return config
    }
  }
  ////console.log("parsed", parsed_config, "unparsed", config)
  return parsed_config
}

export const evalProcess = (process_config, process_state, process_inputs, process_eventctl) => {
  try {
    if (!process_config.func) {
      ////console.log('not func state', process_config, process_state);
      if (process_config.inputs) {
        let inputs = process_config.inputs.map((input, i_input) => {
          if ( utils.isPlainObject(input) || utils.isArray(input) ) {
            if ( !process_config.skip_parse || (process_config.skip_parse && !process_config.skip_parse.includes(i_input))) {
              // console.log("evalProcess (PRE): ", utils.cloneObject(input))
              input = parseConfig(input, process_state, process_inputs, process_eventctl)
              // console.log("evalProcess (POST): ", utils.cloneObject(input))
            } else {
              // console.log("evalProcess isplainobject ", utils.isPlainObject(input), utils.isArray(input), utils.isPlainObject(input) || utils.isArray(input) )
            //  console.log("evalProcess: skipping input parse")
            }
          }
          // console.log('evalprocess from input:', (input && input.process && ( !process_config.skip_eval || (process_config.skip_eval && !process_config.skip_eval.includes(i_input))))?evalProcess(input, process_state, process_inputs, process_eventctl):input)
          return (input && input.process && ( !process_config.skip_eval || (process_config.skip_eval && !process_config.skip_eval.includes(i_input))))?evalProcess(input, process_state, process_inputs, process_eventctl):input
        })

        if(process_config.freeze_inputs){
          if(utils.isArray(process_config.freeze_inputs)){
            process_config.freeze_inputs.forEach(to_freeze => {
              process_config.freeze_inputs[to_freeze] = inputs[to_freeze]
            });
          }else{
            process_config.inputs = inputs
          }
        }

       //console.log(processes, process_config.process)
        const output = processes[process_config.process](...inputs, process_state, process_inputs, process_eventctl)
        if (typeof process_config.output_interface !== "undefined") {
          return processes["transformObj"](utils.cloneDeep(output), utils.cloneDeep(process_config.output_interface))
        } else {
          return output
        }
      }else {
        const output = processes[process_config.process](process_state, process_inputs, process_eventctl)
        if (typeof process_config.output_interface !== "undefined") {
          return processes["transformObj"](utils.cloneDeep(output), utils.cloneDeep(process_config.output_interface))
        } else {
          return output
        }
      }
    } else {
      if (process_config.callb) {
        if (process_config.inputs) {
          if(process_config.freeze_inputs){
            process_config.inputs = process_config.inputs.map((input, i_input) => {
              if (utils.isArray(process_config.freeze_inputs && !process_config.freeze_inputs.includes(i_input))){
                return input
              }else{
                if ( utils.isPlainObject(input) || utils.isArray(input) ) {
                 //console.log("input CH2: ", input, utils.cloneDeep(process_config));
                  if ( !process_config.skip_parse || (process_config.skip_parse && !process_config.skip_parse.includes(i_input))) {
                    // console.log("evalProcess (PRE): ", utils.cloneObject(input))
                    input = parseConfig(input, process_state, process_inputs, process_eventctl)
                    // console.log("evalProcess (POST): ", utils.cloneObject(input))
                  }
                }
                // console.log('evalprocess from input:', (input && input.process && ( !process_config.skip_eval || (process_config.skip_eval && !process_config.skip_eval.includes(i_input))))?evalProcess(input, process_state, process_inputs, process_eventctl):input)
                return (input && input.process && ( !process_config.skip_eval || (process_config.skip_eval && !process_config.skip_eval.includes(i_input))))?evalProcess(input, process_state, process_inputs, process_eventctl):input
              }
            })
          }

          return (e) => {
            let inputs = process_config.inputs.map((input, i_input) => {
              if ( utils.isPlainObject(input) || utils.isArray(input) ) {
                if ( !process_config.skip_parse || (process_config.skip_parse && !process_config.skip_parse.includes(i_input))) {
                  // console.log("evalProcess (PRE): ", utils.cloneObject(input))
                  input = parseConfig(input, process_state, process_inputs, process_eventctl)
                  // console.log("evalProcess (POST): ", utils.cloneObject(input))
                }
              }
              // console.log('evalprocess from input:', (input && input.process && ( !process_config.skip_eval || (process_config.skip_eval && !process_config.skip_eval.includes(i_input))))?evalProcess(input, process_state, process_inputs, process_eventctl):input)
              return (input && input.process && ( !process_config.skip_eval || (process_config.skip_eval && !process_config.skip_eval.includes(i_input))))?evalProcess(input, process_state, process_inputs, process_eventctl):input})
            e.preventDefault();
            e.stopPropagation();

            let merged_inputs = {
              config: inputs,
              value: [e.target.value]
            }

            if ( process_config.interface ){
              //console.log('pre-interface', utils.cloneDeep(merged_inputs), process_config.interface)
               merged_inputs = utils.cloneDeep(merged_inputs)
               merged_inputs.event = e
               merged_inputs.event.skip_cloneDeep = true
               merged_inputs = processes["transformObj"](merged_inputs, utils.cloneDeep(process_config.interface))
              //console.log('post-interface', utils.cloneDeep(merged_inputs), process_config.interface)
             }else{
               merged_inputs = inputs
             }

            const output = processes[process_config.process](...merged_inputs, process_state, process_inputs, process_eventctl)
            if (typeof process_config.output_interface !== "undefined") {
              return processes["transformObj"](utils.cloneDeep(output), utils.cloneDeep(process_config.output_interface))
            } else {
              return output
            }
          }
        }else {
          return (e) => { 
            e.preventDefault(); 
            e.stopPropagation();

            let merged_inputs = {
              value: [e.target.value]
            }

            let output = null
            if ( process_config.interface ){
              //console.log('pre-interface', utils.cloneDeep(merged_inputs), process_config.interface)
               merged_inputs = processes["transformObj"](utils.cloneDeep(merged_inputs), utils.cloneDeep(process_config.interface))
               //console.log('post-interface', utils.cloneDeep(merged_inputs), process_config.interface)
               output = processes[process_config.process](...merged_inputs, process_state, process_inputs, process_eventctl)
             }else{
               output = processes[process_config.process](process_state, process_inputs, process_eventctl)
            }

            if (typeof process_config.output_interface !== "undefined") {
              return processes["transformObj"](utils.cloneDeep(output), utils.cloneDeep(process_config.output_interface))
            } else {
              return output
            }
          }
        }
      } else {
        if (process_config.inputs) {
          if(process_config.freeze_inputs){
            process_config.inputs = process_config.inputs.map((input, i_input) => {
              if(utils.isArray(process_config.freeze_inputs) && !process_config.freeze_inputs.includes(i_input)){
                return input
              }else{
                if ( utils.isPlainObject(input) || utils.isArray(input) ) {
                 //console.log("input CH2: ", input, utils.cloneDeep(process_config));
                  if ( !process_config.skip_parse || (process_config.skip_parse && !process_config.skip_parse.includes(i_input))) {
                    // console.log("evalProcess (PRE): ", utils.cloneObject(input))
                    input = parseConfig(input, process_state, process_inputs, process_eventctl)
                    // console.log("evalProcess (POST): ", utils.cloneObject(input))
                  }
                }
                // console.log('evalprocess from input:', (input && input.process && ( !process_config.skip_eval || (process_config.skip_eval && !process_config.skip_eval.includes(i_input))))?evalProcess(input, process_state, process_inputs, process_eventctl):input)
                return (input && input.process && ( !process_config.skip_eval || (process_config.skip_eval && !process_config.skip_eval.includes(i_input))))?evalProcess(input, process_state, process_inputs, process_eventctl):input
              }
            })
          }

          return (...inputs) => {
            if ( inputs && inputs.length > 0 ) {
              let config_inputs = utils.cloneDeep(process_config.inputs)
              config_inputs = config_inputs.map((input, i_input) => {
                if ( utils.isPlainObject(input) || utils.isArray(input) ) {
                 //console.log("input CH2: ", input, utils.cloneDeep(process_config));
                  if ( !process_config.skip_parse || (process_config.skip_parse && !process_config.skip_parse.includes(i_input))) {
                    // console.log("evalProcess (PRE): ", utils.cloneObject(input))
                    input = parseConfig(input, process_state, process_inputs, process_eventctl)
                    // console.log("evalProcess (POST): ", utils.cloneObject(input))
                  }
                }
                // console.log('evalprocess from input:', (input && input.process && ( !process_config.skip_eval || (process_config.skip_eval && !process_config.skip_eval.includes(i_input))))?evalProcess(input, process_state, process_inputs, process_eventctl):input)
                return (input && input.process && ( !process_config.skip_eval || (process_config.skip_eval && !process_config.skip_eval.includes(i_input))))?evalProcess(input, process_state, process_inputs, process_eventctl):input
              })
              
              let merged_inputs = {
                config: config_inputs,
                inputs: inputs
              }
              
              if ( process_config.interface ){
               //console.log('pre-interface', utils.cloneDeep(merged_inputs), process_config.interface)
                merged_inputs = processes["transformObj"](utils.cloneDeep(merged_inputs), utils.cloneDeep(process_config.interface))
               //console.log('post-interface', utils.cloneDeep(merged_inputs), process_config.interface)
              }else{
                merged_inputs = merge(config_inputs, inputs)
              }
  
             //console.log("Inputs: ", config_inputs, inputs, merged_inputs);
              const output = processes[process_config.process](...merged_inputs, process_state, process_inputs, process_eventctl); 
              if (typeof process_config.output_interface !== "undefined") {
                return processes["transformObj"](utils.cloneDeep(output), utils.cloneDeep(process_config.output_interface))
              } else {
                return output
              }
            } else {
              let config_inputs = utils.cloneDeep(process_config.inputs)
              config_inputs = config_inputs.map((input, i_input) => {
                if ( utils.isPlainObject(input) || utils.isArray(input) ) {
                 //console.log("input CH2: ", input, utils.cloneDeep(process_config));
                  if ( !process_config.skip_parse || (process_config.skip_parse && !process_config.skip_parse.includes(i_input))) {
                    // console.log("evalProcess (PRE): ", utils.cloneObject(input))
                    input = parseConfig(input, process_state, process_inputs, process_eventctl)
                    // console.log("evalProcess (POST): ", utils.cloneObject(input))
                  }
                }
                // console.log('evalprocess from input:', (input && input.process && ( !process_config.skip_eval || (process_config.skip_eval && !process_config.skip_eval.includes(i_input))))?evalProcess(input, process_state, process_inputs, process_eventctl):input)
                return (input && input.process && ( !process_config.skip_eval || (process_config.skip_eval && !process_config.skip_eval.includes(i_input))))?evalProcess(input, process_state, process_inputs, process_eventctl):input
              })

              if(process_config.freeze_inputs){
                if(utils.isArray(process_config.freeze_inputs)){
                  process_config.freeze_inputs.forEach(to_freeze => {
                    process_config.freeze_inputs[to_freeze] = config_inputs[to_freeze]
                  });
                }else{
                  process_config.inputs = config_inputs
                }
              }
              
             //console.log("Inputs: ", config_inputs, inputs, merged_inputs);
              let merged_inputs = {
                config: config_inputs,
                inputs: inputs
              }
              
              if ( process_config.interface ){
              //console.log('pre-interface', utils.cloneDeep(merged_inputs), process_config.interface)
                merged_inputs = processes["transformObj"](utils.cloneDeep(merged_inputs), utils.cloneDeep(process_config.interface))
              //console.log('post-interface', utils.cloneDeep(merged_inputs), process_config.interface)
              }else{
                merged_inputs = merge(config_inputs, inputs)
              }
              const output = processes[process_config.process](...merged_inputs, process_state, process_inputs, process_eventctl);
              if (typeof process_config.output_interface !== "undefined") {
                return processes["transformObj"](utils.cloneDeep(output), utils.cloneDeep(process_config.output_interface))
              } else {
                return output
              }
            }
          }
        }else {
          return (...inputs) => { 
            inputs = inputs.map((input, i_input) => {
              if ( utils.isPlainObject(input) || utils.isArray(input) ) {
                if ( !process_config.skip_parse || (process_config.skip_parse && !process_config.skip_parse.includes(i_input))) {
                  // console.log("evalProcess (PRE): ", utils.cloneObject(input))
                  input = parseConfig(input, process_state, process_inputs, process_eventctl)
                  // console.log("evalProcess (POST): ", utils.cloneObject(input))
                }
              }
              // console.log('evalprocess from input:', (input && input.process && ( !process_config.skip_eval || (process_config.skip_eval && !process_config.skip_eval.includes(i_input))))?evalProcess(input, process_state, process_inputs, process_eventctl):input)
              return (input && input.process && ( !process_config.skip_eval || (process_config.skip_eval && !process_config.skip_eval.includes(i_input))))?evalProcess(input, process_state, process_inputs, process_eventctl):input})

            // console.log("function ran: ",process_config, process_state, process_inputs, process_eventctl)
            const output = processes[process_config.process](...inputs, process_state, process_inputs, process_eventctl);
            if (typeof process_config.output_interface !== "undefined") {
              return processes["transformObj"](utils.cloneDeep(output), utils.cloneDeep(process_config.output_interface))
            } else {
              return output
            }
          }
        }
      }
      
      ////console.log('func state', process_config, process_state);
      
    }
  } catch (error) {
     console.log(error.name, error.message, error.stack,process_config, process_state, process_inputs, arguments)
    return null
  }
}

const merge = (...inputs) => {
  const inputs_1 = inputs[0]
  const inputs_2 = inputs[1]

  const obj_1 = Object.assign({}, inputs_1)
  const obj_2 = Object.assign({}, inputs_2)

  const merge = {...obj_1, ...obj_2}

  return Object.values(merge);
}