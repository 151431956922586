import dayjs from 'dayjs'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import isBetween from 'dayjs/plugin/isBetween'
import 'dayjs/locale/en'; // English
import 'dayjs/locale/es'; // Spanish
import 'dayjs/locale/fr'; // French
import 'dayjs/locale/de'; // German
import 'dayjs/locale/zh-cn'; // Chinese (Simplified)
import 'dayjs/locale/ar'; // Arabic
import 'dayjs/locale/ru'; // Russian
import 'dayjs/locale/ja'; // Japanese

import weekday from 'dayjs/plugin/weekday';
import isoWeek from 'dayjs/plugin/isoWeek';

dayjs.extend(weekday);
dayjs.extend(isoWeek);

dayjs.extend(isSameOrBefore)
dayjs.extend(isSameOrAfter)
dayjs.extend(isBetween)

export const now = (...inputs) => {
  const format = inputs.length > 3 ? inputs[0] : 'YYYY-MM-DD'
  const language = inputs.length > 4 ? inputs[1] : 'en'
  return dayjs().locale(language).format(format)
}

export const getDate = (...inputs) => {
  const date = inputs[0]
  const format = inputs.length > 4 ? inputs[1] : 'YYYY-MM-DD'
  const language = inputs.length > 5 ? inputs[2] : 'en'
  return dayjs(date).locale(language).format(format)
}

export const getWeekNumber = (...inputs) => {
  const date = inputs[0]
  return dayjs(date).isoWeek();
}

export const getCalendarDays = (...inputs) => {
  const year = inputs[0]
  const month = inputs[1] - 1
  const long_date = inputs.length >= 6 ? inputs[2] : false
  // Create a date for the first day of the given month
  const firstDayOfMonth = dayjs(new Date(year, month, 1));

  // Find out the weekday of the first day of the month
  const startDay = firstDayOfMonth.day();

  // Calculate the last day of the previous month
  const lastDayOfPrevMonth = firstDayOfMonth.subtract(1, 'day').date();

  // Calculate total days in the current month
  const daysInMonth = firstDayOfMonth.daysInMonth();

  // Create a date for the last day of the given month
  const lastDayOfMonth = dayjs(new Date(year, month, daysInMonth));

  // Find out the weekday of the last day of the month
  const endDay = lastDayOfMonth.day();

  // Create an array for the days
  let days = [];

  // Add days from the previous month if necessary
  for (let i = startDay - 1; i >= 0; i--) {
    if(long_date){
      days.push([lastDayOfPrevMonth - i, month, year]);
    }else{
      days.push(lastDayOfPrevMonth - i);
    }
  }

  // Add days of the current month
  for (let i = 1; i <= daysInMonth; i++) {
    if(long_date){
      days.push([i, month+1, year]);
    }else{
      days.push(i);
    }
  }

  // Add days from the next month to complete the week
  for (let i = 1; i < 7 - endDay; i++) {
    if(long_date){
      days.push([i, month+2, year]);
    }else{
      days.push(i);
    }
  }


  let weeks = [];
  for (let i = 0; i < days.length; i += 7) {
      weeks.push(days.slice(i, i + 7));
  }

  return weeks;
}

export const dateBefore = (...inputs) => {
  const date_1 = inputs[0]
  const date_2 = inputs[1]
  const format = inputs.length > 5 ? inputs[2] : 'YYYY-MM-DD'
  const language = inputs.length > 6 ? inputs[3] : 'en'

  return dayjs(date_1).isBefore(dayjs(date_2)).locale(language).format(format);
}

export const dateAfter = (...inputs) => {
  const date_1 = inputs[0]
  const date_2 = inputs[1]
  const format = inputs.length > 5 ? inputs[2] : 'YYYY-MM-DD'
  const language = inputs.length > 6 ? inputs[3] : 'en'

  return dayjs(date_1).isAfter(dayjs(date_2)).locale(language).format(format);
}

export const dateSame = (...inputs) => {
  const date_1 = inputs[0]
  const date_2 = inputs[1]
  const format = inputs.length > 5 ? inputs[2] : 'YYYY-MM-DD'
  const language = inputs.length > 6 ? inputs[3] : 'en'

  return dayjs(date_1).isSame(dayjs(date_2)).locale(language).format(format);
}

export const dateSameBefore = (...inputs) => {
  const date_1 = inputs[0]
  const date_2 = inputs[1]
  const format = inputs.length > 5 ? inputs[2] : 'YYYY-MM-DD'
  const language = inputs.length > 6 ? inputs[3] : 'en'

  return dayjs(date_1).isSameOrBefore(dayjs(date_2)).locale(language).format(format);
}

export const dateSameAfter = (...inputs) => {
  const date_1 = inputs[0]
  const date_2 = inputs[1]
  const format = inputs.length > 5 ? inputs[2] : 'YYYY-MM-DD'
  const language = inputs.length > 6 ? inputs[3] : 'en'

  return dayjs(date_1).isSameOrBefore(dayjs(date_2)).locale(language).format(format);
}

export const dateBetween = (...inputs) => {
  const date_1 = inputs[0]
  const date_2 = inputs[1]
  const date_3 = inputs[2]
  const format = inputs.length > 5 ? inputs[3] : 'YYYY-MM-DD'
  const language = inputs.length > 6 ? inputs[4] : 'en'

  return dayjs(date_1).isSameOrBefore(dayjs(date_2), dayjs(date_3)).locale(language).format(format);
}

export const dateDiff = (...inputs) => {
  const date_1 = inputs[0]
  const date_2 = inputs[1]
  const unit = inputs.length > 5 ? inputs[2] : 'ms'
  const float = inputs.length > 6 ? inputs[3] : false

  return dayjs(date_1).diff(dayjs(date_2), unit, float);
}